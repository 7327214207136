import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Nav = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `0px`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0.8rem 0.8rem`,
        fontFamily: `Lato`,
      }}
    >
    
    <Link
      to="/"
      style={{
        color: `black`,
        textDecoration: `none`,
      }}
    >
      {siteTitle}
    </Link>

    <span style={{ 
      float: "right",
     }}>
        <Link
          to="/covid"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          COVID
        </Link>
    </span>

    <span style={{ 
      float: "right",
      marginRight: "0.4rem",
     }}>
        <Link
          to="/mortgages"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          Mortgages
        </Link>
    </span>

    <span style={{ 
      float: "right",
      marginRight: "0.4rem",
     }}>
        <Link
          to="/crypto"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          Crypto
        </Link>
    </span>

    <span style={{ 
      float: "right",
      marginRight: "0.4rem",
     }}>
        <Link
          to="/loans"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          Loans
        </Link>
    </span>

    <span style={{ 
      float: "right",
      marginRight: "0.4rem",
     }}>
        <Link
          to="/about"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          About
        </Link>
    </span>
    
    
    
    </div>
  </header>
)

Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav
